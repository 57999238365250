<template>
  <div class="console-home admin-root-view">
    <div class="admin-container">
      <AdminMenu class="admin-container-menu"/>
      <div class="admin-container-view">
        <router-view></router-view>
      </div>
    </div>
    <LoadingPlaceholder
      class="console-home__loading-placeholder"
      v-if="false"
    />
    <all-svg></all-svg>
  </div>
</template>

<style lang="scss">
</style>

<style lang="scss" scoped>
.console-home {
  --console-home__dynamic-content--MaxWidth: calc(
    var(--global__console-home--Width) - var(--global__senior-menu--Width)
  );

  margin: auto;
  position: relative;
  background: var(--global--primary--BackgroundColor);

  &__super-menu {
    z-index: var(--global__console-home--super-menu--ZIndex);
  }

  & > .console-home {
    &__footer,
    &__header,
    &__loading-placeholder,
    &__super-menu {
      padding-left: var(--global__console-home--paddingLeft);
      padding-right: var(--global__console-home--paddingRight);
    }
  }

}
</style>

<script>
import { mapState } from 'vuex';

import '@/assets/styles/global/styles.scss';
import '@/assets/styles/global/admin/main.scss';
import AllSvg from '@/components/Svg/AllSvg.vue';
import { User } from '@/store/modules/user';
import { Project } from '@/store/modules/project';

import AdminMenu from '@/components/admin/AdminMenu.vue';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';

export default {
  components: {
    AllSvg,
    AdminMenu,
    LoadingPlaceholder,
  },

  watch: {
    $route(to, from) {
      if (to !== from) {
        this.fetchBootData();
      }
      if (this.activeProject.key !== to.params.projectKey) {
        this.fetchData();
      }
      window.scrollTo(0, 0);
    },
  },

  data() {
    return {
      createProjectLink: Project.createProjectLink(),
    };
  },
  created() {
    return this.$store.dispatch('user/fetchActiveUser').then(() => {
      this.fetchData();
      this.fetchBootData();
    }).catch(() => {
      this.$router.push({ name: 'Login', query: { next_url: this.$route.fullPath } });
    });
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      activeProjectState: (state) => state.project.activeProjectState,
      boot: (state) => state.user.boot,
    }),
  },
  methods: {
    fetchData() {
      const { projectKey } = this.$route.params;
      if (projectKey) {
        this.$store.dispatch('project/fetchProject', projectKey).then(() => {
          this.fetchProjects();
        }).catch((err) => {
          console.log('selected project error', err);
        });
      } else {
        this.fetchProjects();
      }
    },
    fetchProjects() {
      this.$store.dispatch('project/fetchProjects').catch((err) => {
        console.log('Error on fetch projects', err);
      });
    },
    fetchBootData() {
      User.fetchBootApi().then((resp) => {
        if (localStorage.getItem('version')) {
          if (localStorage.getItem('version') !== resp.backend_version) {
            window.location.reload();
          }
        }
        if (resp) {
          localStorage.setItem('version', resp.backend_version);
        }
      });
    },
  },
};
</script>
<style lang="scss">
  .screen-admin-pagex {
    h1 {
      font-size: size(32);
      margin: size(30) 0;
    }

    h2 {
      font-size: size(28);
      margin: size(24) 0;
    }

    .filter-tools { //done
      margin-bottom: size(15);
      padding: size(30);
      background-color: #e1eaff;
      border-radius: size(10);

      label {
        padding-right: size(10);
      }

      button, .btn {
        padding: size(5) size(20);
        display: inline-block;
        margin-right: size(10);

        &:disabled {
          opacity: 0.4;
        }

        &:hover {
          color: #eee;
        }
      }
    }

    .item-listx { //done
      background: #fff;
      border-radius: size(10);
      padding: size(20);
      margin-bottom: size(20);

      table {
        position: relative;
        border-collapse: collapse;
        text-align: left;

        th, td {
          padding: size(10) size(10);
          max-width: size(320);
        }

        thead {
          th {
            background: white;
            font-weight: bold;
            position: sticky;
            top: 0;
          }
        }
        tr {
          &:hover {
            background: #f4f4f4;
          }
          td {
            border-bottom: size(1) solid #eaeaea;
          }
        }
      }
    }

    .item-list-row { //done
      display: flex;
      .item-list-col {
        margin-right: size(30);
      }
    }

    .item-report-summary { //done
      margin-bottom: size(20);
      p {
        font-size: size(12);
      }

      table {
        margin-bottom: size(10);
        tr {
          &.inr, &.total {
            td {
              border-color: var(--global--main--title--Color);
              border-width: size(2);
            }
          }
          &.total {
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
