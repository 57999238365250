<template>
  <div class="admin-senior-menu-container">
    <div class="logo">
      <a href="/v2/" title="Home page" rel="noopener">
        <img class="logo__img" src="@/assets/img/logo-rz.svg" alt="roanuz sports logo" />
      </a>
    </div>
    <div class="senior-menu">
      <template  v-for="(route, prop, index) in routes" :key="index">
        <router-link
        :to="{ name: route.routeName }"
        class="senior-menu__item"
        :class="{'active': $route.name === route.routeName}"
        >
        <svg class="senior-menu__item--icon" v-if="route.svgIcon">
          <use v-bind="{ 'xlink:href': route.svgIcon }" />
        </svg>
        </router-link>
        <span class="title">{{route.displayName}}</span>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.admin-senior-menu-container {
  width: size(80);
  max-width: size(80);
  background: var(--admin--color-level1);

  .logo {
    padding: size(26) size(20);
    a {
      display: inline-block;
    }
  }
  .senior-menu {
    padding: size(20) size(10);
    text-align: center;
  }
}

.senior-menu {
  .title {
    display: inline-block;
    font-size: size(10);
    color: var(--global--white--Color);
    margin-bottom: size(25);
  }
  & &__item {
    display: block;
    text-align: center;
    padding: size(11) size(10);
    margin: 0 size(10) 0 ;
    border-radius: 50%;
    &--text {
      display: inline-block;
      padding-left: size(12);
      color: var(--global--link--dark--Color);
    }
    &.router-link-active {
      background: var(--admin--color-level4);
    }
  }
  svg {
    height: size(14);
    width: size(14);
    fill: var(--global--white--Color);
  }
  & &__item {
    &.active,
    &:hover {
      svg {
        fill: var(--global--white--Color);
      }
      .senior-menu__item--text {
        color: var(--global--white--Color);
      }
    }
  }
}
</style>

<script>

export default {
  data() {
    return {
      routes: [
        {
          routeName: 'adminProjects',
          displayName: 'Projects',
          svgIcon: '#dashboard-icon',
        },
        {
          routeName: 'invoiceList',
          displayName: 'Invoices',
          svgIcon: '#billing-icon',
        },
        {
          routeName: 'transactionList',
          displayName: 'Transactions',
          svgIcon: '#transaction-icon',
        },
        {
          routeName: 'adminUsageReport',
          displayName: 'Usage',
          svgIcon: '#usage-icon',
        },
        {
          routeName: 'adminUsersInfo',
          displayName: 'Users',
          svgIcon: '#user-icon',
        },
        // {
        //   routeName: 'adminDiscountInfo',
        //   displayName: 'Discounts',
        //   svgIcon: '#discount-icon',
        // },
        {
          routeName: 'adminReports',
          displayName: 'Reports',
          svgIcon: '#reports-icon',
        },
        {
          routeName: 'adminSalesProcessList',
          displayName: 'Sales Process',
          svgIcon: '#reports-icon',
        },
        {
          routeName: 'adminCouponInfo',
          displayName: 'Coupons',
          svgIcon: '#discount-icon',
        },
        {
          routeName: 'adminPackageList',
          displayName: 'Packages',
          svgIcon: '#reports-icon',
        },
      ],
    };
  },
};
</script>
